<template>
  <form class="space-y-6 w-full">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Autocomplete placeholder="Meklēt klientu no kataloga" :params="{ without_main_customer: true }" @autocompleteItem="getCustomer" url="/api/fetch_customers" :additionalFields="['address', 'reg_nr']" />
      </div>

      <template v-if="customer">
        <div class="col-span-1 md:col-span-2">
          <div class="flex flex-wrap">
            <ItemText title="Klients" :text="customer.name" />
            <ItemText title="Reģ. nr." :text="customer.reg_nr" />
          </div>
        </div>

        <template v-if="customer.branches.length > 0">
          <div class="col-span-1">
            <Select v-model="form.branch" :items="customer.branches" :errors="errors.branch" placeholder="Klienta filiāle" />
          </div>
        </template>

      </template>

    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button @click="hideEditItemForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
          Atcelt
        </button>

        <template v-if="customer && form.branch">
          <template v-if="!loading">
            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
              Saglabāt
            </button>
          </template>
          <template v-else>
            <Loading />
          </template>
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import Validation from "@/modules/Validation";
import Autocomplete from "@/components/Components/Autocomplete"
import Select from "@/components/Components/Select"
import ItemText from "@/components/Components/ItemText"
import Loading from "@/components/Components/ItemText"

export default {
  name: "ChangeInventoryItemOwner",
  components: {
    Autocomplete,
    Select,
    ItemText,
    Loading,
  },
  props: ["item"],
  data: () => ({
    customer: null,
    form: {
      branch: null,
    },
  }),
  computed: {
    ...mapGetters({
      mainCustomer: 'systemMainCustomer',
      errorMessages: "inventoryErrorMessages",
      loading: "loading",
      errors: "errors",	
    }),
    formValidation() {
      return {
        branch: {
          rules: ['required']
        },
      }
    },
  },
  methods: {
    getCustomer(value) {
      this.branch = null
      this.customer = value
    },
    hideEditItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
      this.$store.dispatch("getSingleInventoryItem", {
        uuid: this.$route.params.itemUUID,
      });
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateInventoryItemDetails", {
          id: this.item.id,
          uuid: this.item.uuid,
          customer_id: this.customer.id,
          branch_id: this.form.branch.id,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>